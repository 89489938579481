<template>
  <div>
    <div v-if="id"
         class="text-right mb20 mt20">
      <el-link type="primary"
               style="margin-right: 20px"
               @click="downloadFile">下载协议文件</el-link>
    </div>
    <div v-html="pageHtml"
         class="statement-read" />
  </div>

</template>

<script>
import { PreviewByContent, DownloadByContent } from '@/api/thirdPartCompliance/thirdPartCompliance'
export default {
  data () {
    return {
      pageH: `${document.body.clientHeight}px`,
      accessToken: localStorage.getItem('accessToken'),
      id: this.$route.query.id,
      iframeSrc: '',
      pageHtml: '',
    }
  },
  created () {
    document.body.className = 'bg-white'
    if (this.id) {
      this.previewByContent()
    } else {
      this.pageHtml = localStorage.getItem('htmlStr')
    }
  },
  methods: {
    previewByContent () {
      PreviewByContent(this.$route.query.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.pageHtml = resData.data
        }
      })
    },
    downloadFile () {
      DownloadByContent(this.id)
    }
  },
}
</script>

<style lang="scss">
.statement-read {
  width: 1200px;
  margin: 20px auto;
  table {
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;
    border-spacing: 0;
    page-break-inside: avoid !important;
    border-collapse: collapse;
  }
  td,
  th {
    box-sizing: border-box;
    border: 1px solid #666666;
    page-break-inside: avoid !important;
  }
}
</style>
